.user_mode {
    .accordion-header {
      padding: 0;
        .pageElement {
          margin-top: 0;
          margin-bottom: 0;
          h3 {
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 700;
            font-style: normal;
            background: $color-secondary;
            color: #fff;
            border-bottom: 1px solid #fff;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 25px;
            padding-right: 25px;
            cursor: pointer;
            &:before {
              content: '+';
              text-align: center;
              width: 20px;
              position: absolute;
              right: 30px;
            }
            &:after {
                display: none;
            }
          }
        }
    }
    .accordion-content-container {
      background: #fff;
      max-height: 0;
      max-width: $content-area-width;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      transition: max-height .5s ease-in-out, padding .5s ease-in-out;
      .accordion-content {
        margin: 0;
      }
      &.open {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
        max-height: 4000px;
      }
    }
  }
  