// Page Title (display Body Header)
$page-title-full-width: false !default;
$page-title-full-width-text: false !default;
$page-title-container-background: $content-area-background !default;
$page-title-text-color: #333 !default;
$page-title-font-size: 30px !default;
$page-title-text-case: none !default;
$page-title-font-weight: normal !default;
$page-title-text-align: null !default;

#displayBodyHeader {
  display: flex;
  align-items: center;
  padding-top: 20% !important;
	max-width: 100%;
	position: relative;
  span {
    margin-top: -20%;
    display: block;
    color: $color-primary;
    line-height: 1;
		position: relative; //margin-left: calc((100% - 1260px) / 2);
		text-shadow: 0 0 15px rgba(0,0,0,.1);
		position: relative;
    z-index: 1;
    font-size: $page-title-font-size;
    text-transform: $page-title-text-case;
    font-weight: $page-title-font-weight;
    font-style: $page-title-font-style;
    text-align: $page-title-text-align;
    @media screen and (max-width: $breakpoint-sm-max) {
      font-size: 20px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 2px;
      bottom: -10px;
      width: 50px;
      border-bottom: 5px solid $color-secondary;
    }
  }
  &.displayBodyHeader-image {
    text-indent: 0;
    span {
      color: $page-title-text-color;
    }
    .has-sub-nav & {
      margin-top: -45px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.85) 0%, transparent 70%, transparent 100%);
    }
  }
}

@media only screen and (min-width:768px) {
  #displayBodyHeader {
    span {
      font-size: 60px;
      &:after {
        width: 100px;
        border-bottom: 10px solid $color-secondary;
      }
    }
  }
}

@media only screen and (min-width:1100px) {
  #displayBodyHeader {
    span {
      font-size: 80px;

    }
  }
}

@media only screen and (min-width:1300px) {
  #displayBodyHeader {
    span {
      font-size: 90px;
      margin-left: calc((100% - 1260px) / 2);

    }
  }
}

