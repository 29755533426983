.custom-footer-extra {
  background-color: $color-black;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 130px;
  @media screen and (max-width: 1024px) {
    flex-flow: column;
    padding-bottom: 0;
  }
  .footer-title {
    flex-basis: 100%;
    padding-top: 60px;
    width: 100%;
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-top: 20px;
    }
    .textBlockElement {
      h3 {
        color: #fff;
        font-size: 32px;
        border-bottom: none;
        display: flex;
        justify-content: center;
        white-space: nowrap;
        @media screen and (max-width: $breakpoint-sm-max) {
          font-size: 28px;
        }
        @media screen and (max-width: 320px) {
          font-size: 24px;
        }
        &:before {
          content: '';
          display: inline-block;
          background-color: #fff;
          margin: auto 20px;
          max-width: 430px;
          height: 2px;
          width: 100%;
          transform: none;
          @media screen and (max-width: $breakpoint-sm-max) {
            margin: auto 3px auto 0;
          }
        }
        &:after {
          content: '';
          display: inline-block;
          background-color: #fff;
          margin: auto 20px;
          max-width: 430px;
          height: 2px;
          width: 100%;
          transform: none;
          @media screen and (max-width: $breakpoint-sm-max) {
            margin: auto 0px auto 5px;
          }
        }
      }
    }
  }
  .footer-logo {
    padding-right: 50px;
    @media screen and (max-width: 1024px) {
      padding-right: 0;
    }
  }
  .footer-nav {
    padding: 0 50px;
    @media screen and (max-width: 1024px) {
      padding: 0;
    }
    .textBlockElement {
      h3 {
        font-family: $font-secondary;
        font-size: 20px;
        color: #fff;
        font-style: normal;
        border-bottom: none;
        @media screen and (max-width: 1024px) {
          text-align: center !important;
        }
        &:after {
          display: none;
        }
      }
      .text {
        p {
          font-family: $font-secondary;
          font-size: 14px;
          color: #fff;
          font-weight: 700;
          @media screen and (max-width: 1024px) {
            text-align: center;
          }
          a {
            font-family: $font-secondary;
            font-size: 16px;
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
  }
  .footer-social {
    padding-bottom: 85px;
    @media screen and (max-width: 1024px) {
      padding-bottom: 0;
    }
    .sn-social-media-list {
      @media screen and (max-width: 1024px) {
        text-align: center;
      }
     .footer-social-title {
        .textBlockElement {
          margin-bottom: 10px;
          h3 {
            font-family: $font-secondary;
            font-size: 20px;
            color: #fff;
            font-style: normal;
            border-bottom: none;
            padding-left: 10px;
            &:after {
              display: none;
            }
          }
       }
     }
      .sn-social-media-icon {
        background: transparent;
        font-size: 36px;
        transition: background-color $transition-linear;
        &:hover {
          background-color: $color-secondary;
        }
      }
    }
  }
}

// Edit mode UI
.custom-footer-extra{
  @include edit_ui_element($padding: 10px);
  &:empty{ display: none; }
}
