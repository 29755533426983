// Aggregator News

$agg-news-element-entry-title-font: $font-secondary;
$agg-news-element-entry-title-size: 24px;
$agg-news-element-entry-title-weight: bold;
$agg-news-element-entry-title-line-height: 1.5;
$agg-news-element-entry-title-color: $color-black;
$agg-news-element-entry-title-color-hover: $color-primary;
$agg-news-element-entry-title-case: uppercase;

$agg-news-element-entry-meta-font: $font-primary;
$agg-news-element-entry-meta-size: 12px;
$agg-news-element-entry-meta-weight: bold;
$agg-news-element-entry-meta-line-height: 1.5;
$agg-news-element-entry-meta-color: $color-gray-md;
$agg-news-element-entry-meta-case: null;
$agg-news-element-entry-meta-padding: 0px 0px 10px 0px;

$agg-news-element-entry-body-font: $font-secondary;
$agg-news-element-entry-body-size: 16px;
$agg-news-element-entry-body-weight: normal;
$agg-news-element-entry-body-line-height: 1.5;
$agg-news-element-entry-body-color: $color-black;
$agg-news-element-entry-body-case: null;
$agg-news-element-entry-body-padding: 5px 0px 0px 0px;

$agg-news-element-entry-preview-image-width: 100%;
$agg-news-element-entry-preview-image-width-alt: 41%;
$agg-news-element-entry-preview-image-aspect-ratio: aspect-to-percent(3, 2);
$agg-news-element-entry-preview-image-margin: 0 20px 0 0;
$agg-news-element-entry-preview-image-float: true;

$agg-news-element-entry-button-padding: 10px 0px 0px 0px;
$agg-news-element-entry-padding-y: 0px;

// RESETS
.newsAggregatorElement{
  .condensed ul{
    list-style: none;
    padding-right: 20px;
    @media screen and (max-width: 1024px) {
      padding: 10px 10px 20px 10px;
    }
    li:empty{ display: none; }
  } 
}


// List Styles
.newsAggregatorElement{
  .item:first-of-type {
    @media screen and (max-width: 1024px) {
      margin-top: 0;
    }
  }
  .condensed,
  .expanded{
    border-bottom: 2px solid #fff;
    padding: $agg-news-element-entry-padding-y 0px;
    background: #f1f1f1;
    display: flex;
    flex-flow: row;
    align-items: center;
    @media screen and (max-width: 1024px) {
      flex-flow: row wrap;
      margin-top: 10px;
    }
  }
  .aggHeader + .condensed,
  .aggHeader + .expanded {
    margin-top: 15px;
    border-top: 2px solid #fff;
  
  }
  .extendedOptions {
    margin-top: 0;
    padding: 10px;
    background: #f1f1f1;
    a:before {
      display: none;
    }
  }
}  


.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .condensed > a{
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @media screen and (max-width: 1024px) {
      margin: 0;
    }
    @media only screen and (min-width: 1024px) {
      max-width: $agg-news-element-entry-preview-image-width-alt;
      min-width: $agg-news-element-entry-preview-image-width-alt;
    }
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      border-right: 5px solid $color-secondary;
      @media screen and (max-width: 1024px) {
        border-right: 0;
        border-bottom: 5px solid $color-secondary;
      }
    }
  }

  // ENTRY TITLE
  .condensed .details h4 a{
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    @media screen and (max-width: $breakpoint-sm-max) {
      font-size: 18px;
      line-height: 1;
    }
    &:hover{
      color: $agg-news-element-entry-title-color-hover;
      text-decoration: underline;
    }
  }

  // ENTRY META
  .condensed .dateAuthor{
    line-height: $agg-news-element-entry-meta-line-height;
    padding: $agg-news-element-entry-meta-padding;
  }
  .condensed .newsAuthor,
  .condensed .newsDate{
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  }

  // ENTRY BODY (teaser)
  .condensed .teaser{
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
    @media screen and (max-width: $breakpoint-sm-max) {
      font-size: 12px;
      padding: 0 0 5px 0;
    }
  }

  // ENTRY BUTTON
  .condensed .commentsAndMore{
    padding: $agg-news-element-entry-button-padding;
    .button-content {
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .condensed .readMore .button-small{
    display: none;
  }
}


// EXPANDED
.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .expanded .newsItemHeader > a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @media only screen and (min-width: 1024px) {
      max-width: $agg-news-element-entry-preview-image-width-alt;
      min-width: $agg-news-element-entry-preview-image-width-alt;
    }
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      border-right: 5px solid $color-secondary;
    }
  }

  // ENTRY TITLE
  .expanded .newsItemHeader h2 a {
    display: block;
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    @media screen and (max-width: $breakpoint-sm-max) {
      font-size: 18px;
      line-height: 1;
    }
    &:hover{
      color: $agg-news-element-entry-title-color-hover;
      text-decoration: underline;
    }
  }

  // ENTRY META
  .expanded .dateAuthor{
    line-height: $agg-news-element-entry-meta-line-height;
    padding: $agg-news-element-entry-meta-padding;
  }
  .expanded .newsAuthor,
  .expanded .newsDate{
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  }

  // ENTRY BODY (teaser)
  .expanded .newsItemElement > h4 {
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
    @media screen and (max-width: $breakpoint-sm-max) {
      font-size: 12px;
      padding: 0 0 5px 0;
    }
  }

  // ENTRY BUTTON
  .expanded .commentsAndMore{
    margin: 0;
    .commentCount{
      padding: $agg-news-element-entry-button-padding;
      display: inline-block;
    }
    .button-content{
      display: inline-block;     
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .expanded .readMore .button-small{
    @include global-link-style;
    @include element-link-container;
    @include element-link-text($align: center);
    display: inline-block;
  }
}
