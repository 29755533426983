$slider-apsect-ratio-padding: calc(100% / 3);
$slider-text-max-width: 860px;
$slider-text-max-width-mobile:320px;
$slider-height:535px;
$slider-height-mobile:127px;
$slider-height-tablet:255px;

.user_mode {
  .home-media-slider {
      padding-left: 0;
      padding-right: 0;
    .column {
      padding: 0;
    }
    .mediaSlider {
      margin: 0;
      &>p {
        display: none;
      }
    }
    .sn-media-slider {
      height: 0;
      padding-top: $slider-apsect-ratio-padding;
      .slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        .slide-text {
            @media screen and (min-width: 1024px) and (max-width: 1250px) {
                padding-bottom: 30px;
            }
            .slide-title {
            font-family: $font-primary;
            font-weight: 400;
            font-style: italic;
            font-size: 18px;
            max-width: $slider-text-max-width;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            text-shadow: 0 4px 0 $color-primary;
            line-height: 1;
            margin: auto;
            @media screen and (max-width: $breakpoint-sm-max) {
                margin: 0 0 5% 0;
                text-shadow: 0 2px 0 $color-primary;
            }
            &:after {
                content: '';
                width: 50px;
                border-bottom: 5px solid $color-secondary;
                display: flex;
                margin: 20px auto;
                @media screen and (max-width: $breakpoint-sm-max) {
                    width: 70px;
                    margin: 10px auto;
                }
            }
            &:empty:after {
                display: none;
            }
            }
            .slide-description {
            font-family: $font-primary;
            font-weight: 700;
            font-style: italic;
            font-size: 24px;
            overflow-wrap: normal;
            color: #fff;
            line-height: .85;
            margin: 0;
            position: relative;
            &:empty {
                display: none;
            }
            @media screen and (min-width: 1024px) and (max-width: 1100px) {
                display: none;
            }
            @media screen and (max-width: $breakpoint-sm-max) {
                display: none;
            }
            }
        }
      }
      .slides .slide .media-wrapper {
          &:after {
            content: '';
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0.85) 100%);
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
          }
        img {
          top: 0 !important;
          height: auto !important;
          width: 100% !important;
        }
      }
      .slider-pagination-numbers {
        bottom: 25% !important;
        z-index: 5;
      }

      .slider-pagination-numbers.circular {
        left: 0;
        .paging-item {
          border-radius: 100%;
          height: 10px;
          width: 10px;
          border: 1px solid $color-secondary;
          background: $color-secondary;
          display: inline-block;
          margin: 0 2px;
          @media screen and (max-width: $breakpoint-sm-max) {
              height: 6px;
              width: 6px;
              margin: 0 1.5px;
          }
          .page-number {
            display: none;
          }
          &.flex-active {
            border: 1px solid white;
            background: $color-primary;
          }
        }
      }
      .slider[class$="-paging"] {
        margin: 0;
      }
      .flex-direction-nav {
        a {
          top: 50%;
            @media screen and (max-width: $breakpoint-sm-max) {
                top: 60%;
            }
        }
        .flex-prev {
          opacity: 1;
          left: 3.5%;
        }
        .flex-next {
          opacity: 1;
          right: 3.5%;
        }
        a:before,
        a:after {
          text-shadow: none;
          font-size: 40px;
          font-weight: 700;
          color: $color-secondary;
          @media screen and (max-width: $breakpoint-sm-max) {
              font-size: 30px;
          }
        }
      }
    }
  }
}


// Tablet
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .user_mode {
    .home-media-slider {
      .sn-media-slider {
        .slider {
            .slide-overlay {
                .slide-text {
                    vertical-align: top;
                    .slide-title {
                        font-size: 36px;
                        font-weight: 700;
                        &:after {
                            bottom: -14px;
                            width: 75px;
                            border-bottom: 5px solid $color-secondary;
                          }
                      }
                      .slide-description {
                        font-size: 16px;
                      }
                }
            }
        }
      }
    }
  }
}

// Desktop
@media only screen and (min-width: 1024px) {
  .user_mode {
    .home-media-slider {
      .sn-media-slider {
        .slider {
            .slide-overlay {
                padding: 0;
                .slide-text {
                    .slide-title {
                        font-size: 62px;
                        font-weight: 700;
                        font-style: oblique;
                        text-shadow: 0 4px 0 $color-primary;
                        &:after {
                            bottom: -20px;
                            width: 70px;
                            border-bottom: 5px solid $color-secondary;
                          }
                      }
                      .slide-description {
                        font-size: 20px;
                        font-style: oblique;
                        text-shadow: 0px 2px 0 #191C22;
                      }
                }
            }
        }
      }
    }
  }
}