/* Survey Link Element */
// 1. General Styling
// 2. Accomdidation for Small Screens and Mobile Views

// 1. General Styling
.pageElement.surveyLinkElement{
  background:transparent;
  background-image:none;
  margin-top: 0;
  margin-bottom: 0;
  a{
    display:block;
    width:100%;
    padding:10px;
    color:#fff;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    background: $color-primary;
    margin-bottom: 2px;
    text-transform: uppercase;
    font-family: 'Open Sans';
    transition: background-color $transition-standard;
    &:hover{
      background: $color-secondary;
      span {
        text-decoration: underline;
      }
    }
    span{
      display:inline-block;
      color:#fff;
      text-decoration:none;
      padding-left: 40px;
      &:after{
        content:"\f105";
        display:inline-block;
        margin-left: 1.5ch;
        height:1rem;
        line-height:1rem;
        font-size: 20px;
        font-family: FontAwesome;
        color: #fff;
        cursor: pointer;
        transform: translate(0,-1px);
        transition: color $transition-standard;
      }
    }
    small{
      font-size: 22px;
      font-weight: 700;
    }
  }
}
.pageElement.surveyLinkElement a:hover > span:after{
    color:#fff;
}
