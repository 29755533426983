.sponsor-band {
padding: 20px 0 80px 0;
background: $color-gray-lt;
@media screen and (max-width: $breakpoint-sm-max) {
  padding: 0 0 40px 0;
}
.column {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  .sponsor-title {
    padding-bottom: 40px;
    padding-top: 40px;
    flex-basis: 100%;
    width: 100%;
    h3 {
      font-family: $font-primary;
      font-size: 42px;
      font-style: oblique;
      line-height: 1.5em;
      font-weight: 700;
      text-transform: uppercase;
      color: $color-primary;
      border-bottom: 1px solid $color-primary;
      &:after {
        content: '';
        display: block;
        background-color: $color-secondary;
        height: 5px;
        max-width: 50px;
        width: 100%;
        transform: translateY(3px);
      }
    }
  }
  .pageEl {
      margin: 0 50px;
    img {
      height: auto;
      width: auto;
      max-width: 300px;
      max-height: 110px;
      opacity: 0.6;
      filter: grayscale(100%);
      transition: filter 0.2s linear, opacity 0.4s linear;
      &:hover {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}
}
  
  .has-mobile-nav {
    .sponsor-band {
      .column {
        flex-flow: column;
        .pageEl {
          padding-top: 20px;
          padding-bottom: 20px;
          img {
            max-width: 280px;
            max-height: 100px;
          }
        }
      }
    }
  }
  