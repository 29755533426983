// Global Page Element Headers
.pageElement > h3:first-child{
  @include page-element-header;
}
.pageElement > h3:only-child{
  margin-bottom: 0;
}

// Custom Page Element Headers
.custom-header {
  .pageElement > h3:first-child{
    font-family: $font-secondary;
    font-size: 26px;
    font-style: normal;
    color: #fff;
    background-color: $color-primary;
    border-bottom: none;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    min-height: 60px;
    line-height: 1.8;
    &:after {
      display: none;
    }
  }
  .pageElement > h3:only-child{
    margin-bottom: 0;
  }
}

.linkElement {
  margin-top: 10px;
  margin-bottom: 10px;
}

.documentElement {
  margin-top: 10px;
  margin-bottom: 10px;
  h3.word {
    font-size: 18px;
    font-style: normal;
    text-transform: none;
    border-bottom: none;
    &:hover {
      a {
        text-decoration: underline;
      }
    }
    &:after{
      display: none;
    }
  }
}
